[data-vertical-style="detached"] {
  @media (min-width: 992px) {
    .page {
      width: 100%;
      margin: 0 auto;
      padding-inline-start: 4.25rem;
    }
    .page-header-breadcrumb {
      padding-block-start: 0.2rem;
      // padding-inline-end: 4.45rem;
      padding-inline-end: 1.45rem;
      padding-block-end: 2.5rem;
      padding-inline-start: 18rem;
      position: absolute;
      max-width: 100%;
      inset-inline: 0;
    }
    .app-content {
      margin-block-start: 8.75rem;
      margin-inline-start: 12.5rem !important;
      position: relative;
    }
    .app-header {
      position: absolute;
      z-index: 95;
      padding-inline-start: 0;
      border-block-end: 0;
    }
    .app-sidebar {
      width: 15rem;
      border-radius: 0.6rem;
      inset-block-start: 1rem;
      box-shadow: 0 0.15rem 0.1rem rgba(0, 0, 0, 0.04);
      overflow: hidden;
      inset-block-end: 1.5rem;
      inset-inline-start: 1rem;
      height: 96vh;
      // height: 200vh;//css
      z-index: 99;
      border: 1px solid $menu-border-color;
      .slide__category {
        padding: 1.2rem 1.65rem;
        position: relative;
      }
      .main-sidebar-header {
        border-start-start-radius: 0.6rem;
        border-start-end-radius: 0.6rem;
        display: block !important;
        .header-logo img {
          margin: 0 auto;
        }
      }
    }
    .main-sidebar {
      height: 90vh;
      // height: 100%;//css
      // margin-block-start: 3.72rem !important;
    }
    .app-content {
      margin-inline-start: 15rem;
      position: relative;
    }
    .app-header {
      padding-inline-start: 0;
      .main-header-container {
        width: 100%;
        padding-inline-start: 17rem;
        padding-inline-end: 1.5rem;
        padding-block-start: 8px;
        .form-control {
          border-radius: 50px;
          box-shadow: 0 0.15rem 0.1rem rgba(0, 0, 0, 0.04);
          border-color: rgba(255, 255, 255, 0.05);
          line-height: 20;
          padding: 0.3rem 1rem;
        }
        .header-content-right {
          padding: 10px;
          padding-block-start: 0;
          padding-block-end: 0;
          border-radius: 50px;
          background: rgba(255, 255, 255, 0.1);
          margin-block-start: 10px;
          .header-link-icon {
            margin-block-end: 10px;
          }
          .header-icon-badge {
            inset-block-start: 0.35rem;
          }
        }
      }
      .horizontal-logo {
        padding: 0.85rem 0;
        display: none;
        img {
          height: 2rem;
          line-height: 2rem;
        }
        .desktop-logo {
          display: flex;
        }
        .desktop-dark,
        .toggle-logo,
        .toggle-dark {
          display: none;
        }
      }
    }
    .footer {
      box-shadow: none;
      background-color: transparent !important;
    }
    &[data-header-styles="color"] {
      .main-header-container .header-link-icon:hover {
        background-color: transparent;
      }
    }

    &[data-toggled="detached-close"] {
      .main-header-container {
        padding-inline-start: 8rem;
      }
      .page-header-breadcrumb {
        padding-inline-start: 8rem;
      }
      .app-content {
        margin-inline-start: 2.5rem !important;
      }
      &:not([data-icon-overlay="open"]) {
        .app-sidebar {
          width: 5rem;
          .main-sidebar-header {
            width: 4.9rem;
            .header-logo {
              .toggle-dark {
                display: block;
              }
              .desktop-dark,
              .desktop-logo,
              .toggle-logo{
                display: none;
              }
            }
          }
          .category-name,
          .side-menu__label,
          .side-menu__angle {
            display: none;
          }
          .slide.has-sub.open {
            .slide-menu {
              display: none !important;
            }
          }
          .side-menu__icon {
            margin-inline-end: 0;
          }
          .slide__category {
            padding: 1.2rem 1.65rem;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              inset-inline-start: 2.25rem;
              inset-inline-end: 0;
              inset-block-start: 1.25rem;
              inset-block-end: 0;
              width: 0.35rem;
              border-radius: 3.125rem;
              height: 0.35rem;
              border: 1px solid $menu-prime-color;
              opacity: 1;
            }
          }
          .side-menu__item .badge {
            display: none;
          }
        }
        .side-menu__item {
          justify-content: center;
        }
      }
      &[data-icon-overlay="open"][data-menu-styles="light"][data-theme-mode="dark"] 
        .app-sidebar .main-sidebar-header .header-logo .toggle-logo {
            display: none !important;
      }
    }

    &[data-header-position="fixed"] {
      .app-header.sticky-pin {
        position: fixed;
        .main-header-container {
          padding-block-start: 0;
          .header-content-right {
            background: transparent;
            margin-block-start: 0;
            padding-block-end: 0;
            .header-link-icon {
              margin-block-end: 0;
            }
            .header-icon-badge {
              inset-block-start: 0.75rem;
            }
            #mainHeaderProfile {
              margin-block-start: 0;
            }
          }
        }
      }
    }

    &[data-header-position="rounded"] {
      .app-header.sticky-pin {
        position: fixed;
        margin-inline-start: 18rem;
        width: calc(100% - 19rem);
        transition: .2s ease-in;
        margin-block-start: 15px;
        border-radius: 50px;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
        .main-header-container {
          padding-block-start: 0;
          padding-inline-start: 1.5rem;
          .header-content-right {
            background: transparent;
            margin-block-start: 0;
            padding-block-end: 0;
            .header-link-icon {
              margin-block-end: 0;
            }
            .header-icon-badge {
              inset-block-start: 0.75rem;
            }
            #mainHeaderProfile {
              margin-block-start: 0;
            }
          }
        }
      }
      
    &[data-toggled="detached-close"] {
      &:not([data-icon-overlay="open"]) {
        .app-header.sticky-pin {
          margin-inline-start: 8rem;
          width: calc(100% - 9rem);
        }
      }
    }
    }
  }
}
